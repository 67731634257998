import React from 'react'

import '../css/recently.css'

import Skeleton from 'react-loading-skeleton'
import restApiHost from '..'
import { useGlobalState } from '../state'



function RecentlyOpened ({data, isLoaded}) {
    const ip = useGlobalState('ip')[0];

    async function goToFrame (event, game_id, link) {
        
        event.stopPropagation();
        
        await fetch(restApiHost + `/api/add_recently_game/` + game_id + '/' + ip + `/`);
        const WebApp = window.Telegram.WebApp;


        WebApp.openLink(link);
        
               
            
        };


    return (
        <div className='recently-opened'>
            {isLoaded ? (
              <div>
                {data.length > 0 ?(
                  <div>
                  <div className='recently-opened__text-container'>Недавно открытые</div>
                  <div className='recently-opened__games-container'>
                      {data.map((item, index) => (
                      <div className='recently-opened-game' key={index}>
                            
                            <div className='recently-opened-game__image_container' onClick={(event) => {goToFrame(event, item.id, item.link)}}>
                                <img src={item.main_image} className='recently-opened-image' alt='error'/>
                            </div>
                          <div className='recently-opened-game-title'>{item.title}</div>
                      </div>
                  ))}
                  </div>
                  <hr className='breakable_line'/>
                  </div>) : (<div></div>)}
            </div>) : (<div>
              <div className='recently-opened__text-container'><Skeleton style={{width: '47vw'}}/></div>
              <div className='recently-opened__games-container'>
                  
                  <div className='recently-opened-game'>
                        
                        <div className='recently-opened-game__image_container'>
                            <Skeleton style={{width: '100%', height: '100%'}}/>
                        </div>
                      
                      <div className='recently-opened-game-title'><Skeleton/></div>
                  </div>
                  <div className='recently-opened-game'>
                        
                        <div className='recently-opened-game__image_container'>
                            <Skeleton style={{width: '100%', height: '100%'}}/>
                        </div>
                      
                      <div className='recently-opened-game-title'><Skeleton/></div>
                  </div>
                  <div className='recently-opened-game'>
                        
                        <div className='recently-opened-game__image_container'>
                            <Skeleton style={{width: '100%', height: '100%'}}/>
                        </div>
                      
                      <div className='recently-opened-game-title'><Skeleton/></div>
                  </div>
                  <div className='recently-opened-game'>
                        
                        <div className='recently-opened-game__image_container'>
                            <Skeleton style={{width: '100%', height: '100%'}}/>
                        </div>
                      
                      <div className='recently-opened-game-title'><Skeleton/></div>
                  </div>
                  <div className='recently-opened-game'>
                        
                        <div className='recently-opened-game__image_container'>
                            <Skeleton style={{width: '100%', height: '100%'}}/>
                        </div>
                      
                      <div className='recently-opened-game-title'><Skeleton/></div>
                  </div>
                  <div className='recently-opened-game'>
                        
                        <div className='recently-opened-game__image_container'>
                            <Skeleton style={{width: '100%', height: '100%'}}/>
                        </div>
                      
                      <div className='recently-opened-game-title'><Skeleton/></div>
                  </div>
           
              </div>
              <hr className='breakable_line'/>
              </div>)}
        </div>
    )
}

export default RecentlyOpened;