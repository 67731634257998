import React from 'react';

import '../css/block_display.css'
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import leftVenokImage from '../img/venok_left.png'
import rightVenokImage from '../img/right_venok.png'
import { useGlobalState } from '../state';
import restApiHost from '..';


const Sub = ({ subcategory }) => {
  if (subcategory === 'Новое') {
    return <div className='game-subtitle'>Новое</div>;
  }

  if (subcategory === 'Популярное') {
    return <div className='game-popular'>Популярное</div>
  }

  if (subcategory === 'Выбор редакции') {
      return <div className='game-choose'>
      <div className='venok-container'>
          <img src={leftVenokImage} className='venok' alt='error'/>
      </div>
      <div className='title-sub'>Выбор редакции</div>
      <div className='venok-container'>
          <img src={rightVenokImage} className='venok' alt='error'/>
      </div>
    </div>
  }

  
  return null;
};





const BlockDisplay = ({ data }) => {

  const navigate = useNavigate();
  

  const ip = useGlobalState('ip')[0];

  async function goToFrame (event, game_id, link) {
        
    event.stopPropagation();
    await fetch(restApiHost + `/api/add_recently_game/` + game_id + '/' + ip + `/`);
    
    const WebApp = window.Telegram.WebApp;


    try {
      const row = WebApp.initDataUnsafe.user.id;
      WebApp.openLink(link);
    }
    catch {
        window.location.href = link;
    } 
        
    };

  async function goToPage(game_id) {
      await fetch(restApiHost + `/api/add_recently_game/` + game_id + '/' + ip + `/`);

      navigate(`/game?game_id=${game_id}`);
  }

  
  
  const renderBlocks = () => {
    const blocks = [];
    if (data.length >=1) {
    for (let i = 0; i < data.length; i += 3) {
      const block = data.slice(i, i + 3);
      blocks.push(
        <div key={i} className="scrolable-container__block">
          {block.map((item, index) => (
            <div key={`${i}-${index}`} className='scolable-container__row' onClick={() => goToPage(item.id)}>
              <div className='scrolable-container-im__container'>
                <img className='scrolable-container-im' src={item.main_image} alt='error'/>  
              </div>
              <div className='middle__container'>
                <div className='title__subtitle_container'>
                  <div className='game-tit'>{item.title}</div>
                  <Sub subcategory={item.subcategory}/>
                </div>
                <div className='game-description_el'>{item.short_description}</div>
              </div>
              <div className='button__item__container'>
               
                <button className='go__to_button' onClick={(event) => goToFrame(event, item.id, item.link)}>Открыть</button>
                
              </div>
            </div>
          ))}
        </div>
      );
    }
  }  else {
    const test =[{
      id: 1
    },
    {
      id: 1
    },
    {
      id: 1
    },
    {
      id: 1
    },
    {
      id: 1
    },
    {
      id: 1
    },
  ]
    for (let i = 0; i < test.length; i += 3) {
      const block = test.slice(i, i + 3);
      blocks.push(
        <div key={i} className="scrolable-container__block">
          {block.map((item, index) => (
            <div key={`${i}-${index}`} className='scolable-container__row'>
              <div className='scrolable-container-im__container'>
                <Skeleton style={{width: '100%', height: '100%'}}/>
              </div>
              <div className='middle__container'>
                <div className='title__subtitle_container'>
                  <div className='game-tit'><Skeleton style={{width: '15vw'}}/></div>
                  <div className='game__sub_category' style={{background: 'none'}}><Skeleton/></div>
                </div>
                <div className='game-description_el'><Skeleton/></div>
              </div>
              <div className='button__item__container'>
                <Skeleton style={{width: '74px', height: '30px', borderRadius: '30px 30px 30px 30px', marginLeft: '10px'}}/>
              </div>
            </div>
          ))}
        </div>
      );
    }
  }
    return blocks;
  };

  return (
    <div className="scrolable-container">
      {renderBlocks()}
    </div>
  );
};

export default BlockDisplay;