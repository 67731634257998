import React from 'react'

import searchIcon from '../img/search.png'
import RecentlyOpened from './RecentlyOpened';
import Skeleton from 'react-loading-skeleton';
import BlockDisplay from './BlockDisplay';
import Break from './Break';

function MainPageOffline ()  {


    return (
        <div className='offline-page'>
            <div className='search'>
                <div className='image-search-container'>
                    <img src={searchIcon} className='search-image' alt='error'/>
                </div>
                <input placeholder = 'Поиск' className='search-input' ></input>
            </div>
            <RecentlyOpened isLoaded={false}/>
            <div className='casino'>
            <div className='casino-container'>
                <div className='casino-title'><Skeleton style={{width: '20vw'}}/></div>
                <BlockDisplay data={[]}/> 
            </div>
            <hr className='breakable_line'/>
            </div>
            <div className='casino'>
            <div className='casino-container'>
                <div className='casino-title'><Skeleton style={{width: '20vw'}}/></div>
                <BlockDisplay data={[]}/> 
            </div>
            </div>
            <Break/>
        </div>
                    

    )


}

export default MainPageOffline;