import React from 'react'

import '../css/break.css'
function Break () {

    return (
        <div className='main_break_line'>
        </div>
    )
}

export default Break;