import React from 'react';

import '../css/iframe.css'
import { Link } from 'react-router-dom';
import goBackImage from '../img/go_back.png'
import { useLocation } from 'react-router-dom'

function Iframe() {
    const location = useLocation()
    const { url } = location.state

  return (
    <div className='iframe-page'>
        <div className='iframe_header'>
            <Link className='iframe-go_back_container' to='/'>
                    <div className='iframe-image-container'>
                        <img src={goBackImage} alt='error' className='iframe-image'/>
                    </div>
                    <div className='iframe-header__text'>Вернуться в лобби</div>
            </Link>
        </div>
        <div className='iframe__container'>
          <iframe
            src = {url}
            title='title'
            sandbox='allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-same-origin allow-presentation allow-scripts'
            className='iframe-video'
            />
        </div>
    </div>
  );
}

export default Iframe;