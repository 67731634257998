import React from 'react';
import BlockDisplay from './BlockDisplay';
import { useGlobalState } from '../state';

import '../css/bets.css'
import Skeleton from 'react-loading-skeleton';



function Bets() {
  
  const games = useGlobalState('games')[0].data.filter(item => item.category === "Ставки на спорт");


  return (
    <div className='casino'>
      
      <div className='casino-container'>
        {games.length >= 1 ? (
            <div className='casino-title'>Ставки на спорт</div>
            ) : (<div className='casino-title'><Skeleton style={{width: '20vw'}}/></div>)}
          <BlockDisplay data={games}/> 
      </div>
      <hr className='breakable_line'/>
    
    </div>
  
  );
}

export default Bets;