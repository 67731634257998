import React from 'react'

import utya from '../img/utya-page.gif'

import backgroundImage from '../img/background.png'
import backgroundAndroidImage from '../img/android.png'
import '../css/install-page.css'
import { useState } from 'react'
import homeImage from '../img/home.png'
import { useNavigate } from 'react-router-dom'
import PWAPrompt from './Pwa'


function InstallPwaPage () {

    const navigate = useNavigate();
    const [isButtonClicked, setButtonClicked] = useState(false)

    const detectOS = () => /android/i.test(navigator.userAgent) ? "Android" :
                     /iPad|iPhone|iPod/.test(navigator.userAgent) ? "iOS" : "Android";


    const os = detectOS();
    
    return (
        <div className='install-pwa-page'>
            <div className='i-container'>
                <img src={utya} alt='error' className='i-container-image'/>
            </div>
            {os === 'iOS' ?(
                <div className='vir'>
                {isButtonClicked ? (
                    <div className='vir'>
                        <div className='yarlik'>Инструкция по установке</div>
                            <div className='text-text-text__container'>
                                Нажмите на иконку «Поделиться» в меню браузера. Затем, выберите пункт «На экран домой» и нажмите «Добавить»
                            </div>
                            <div className='i-container-back-second'>
                                <img src={homeImage} alt='error' className='i_container-back-image'/>
                            </div>
                            <button className='main-bu' onClick={() => navigate('/')}>Понятно</button>
                
                            </div>
                ):
                (<div className='vir'>
                <div className='yarlik'> Установите ярлык
                    для быстрого доступа</div>
                    <div className='text-text-text__container'>
                        Это то же самое, что и приложение, только удобнее, и его не нужно скачивать
                    </div>
                    <div className='i-container-back'>
                        <img src={backgroundImage} alt='error' className='i_container-back-image'/>
                    </div>
                    <button className='main-bu' onClick={() => setButtonClicked(true) }>Инструкция по установке</button>
        
                    </div>
                )}
            </div>) : (<div className='vir'>
                <div className='yarlik'> Установите ярлык
                    для быстрого доступа</div>
                    <div className='text-text-text__container'>
                        Это то же самое, что и приложение, только удобнее, и его не нужно скачивать
                    </div>
                    <div className='i-container-back'>
                        <img src={backgroundAndroidImage} alt='error' className='i_container-back-image'/>
                    </div>
                    <PWAPrompt/>
        
                    </div>)}

            
            
        </div>
    
    )
}

export default InstallPwaPage;