import React from "react"
import MainPage from "./pages/MainPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Iframe from "./components/Iframe";
import GamePage from "./components/GamePage";
import { SkeletonTheme } from "react-loading-skeleton";
import InstallPwaPage from "./components/InstallPwaPage";


class App extends React.Component {
    

    render(){
        return (
          <SkeletonTheme baseColor="#92969c" highlightColor="#87898c">
            <BrowserRouter>
            <Routes>
              <Route path="/" element={<MainPage/>} />
              <Route path='/frame' element={<Iframe/>}/>
              <Route path='/game' element={<GamePage/>}/>
              <Route path='/install' element={<InstallPwaPage/>}/>
            </Routes>
          </BrowserRouter>
          </SkeletonTheme>
        )
    }

    

}


export default App