import React from 'react';
import BlockDisplay from './BlockDisplay';
import { useGlobalState } from '../state';

import '../css/casino.css'
import Skeleton from 'react-loading-skeleton';

function Casino() {

  const games = useGlobalState('games')[0].data.filter(item => item.category === "Казино");
  

  return (
    <div className='casino'>
        <div className='casino-container'>
            {games.length >= 1 ? (
            <div className='casino-title'>Казино</div>
            ) : (<div className='casino-title'><Skeleton style={{width: '20vw'}}/></div>)}
            <BlockDisplay data={games}/> 
        </div>
    </div>
  );
}

export default Casino;